@import "../../global.scss";
@import 'swiper/css';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'swiper/scss/scrollbar';
@import "swiper/scss/effect-cube";

.projectsContainer{
    padding-top: 50px;
    padding-bottom: 45px;
    background-image: linear-gradient(rgb(10,0,30),rgb(40,40,55),rgb(40,40,55),rgb(40,40,55));
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    width: 100%;
   
    h1{
        font-size: max(2.5rem, 3vw);
        color: aqua;
    }

    .swiperContainer{
        height: 30%;
        width: 80%;
        @include mobile{
            width: 100%;
        }
    }
    .mySwiper{
     height: 50vh; 
     width: 50%;  

     img{
         width: 100%;
     }
    }
}
