@import "../../global.scss";

.aboutContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
    width: 100%;
    background-image: linear-gradient(rgb(5, 5, 10),rgb(10,0,30),rgb(10,0,30),rgb(10,0,30),rgb(10,0,30),rgb(20,0,60),rgb(10,0,30),rgb(10,0,30));

    h1{
        font-size: max(2rem, 3vw);
        align-self: center;
        color: aqua;
    }
}

.skillsWrapper{
    height: 60vh;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items:center;
    
    @include mobile{
        flex-direction: column;
        height: 90vh;
    
    }
    .skillCard{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height:90%;
        width: 27%;
        max-width: 500px;
        background-color: rgb(96, 120, 145);
        border: 1px solid aqua;
        border-radius: 2%;
        color: aqua;

        @include mobile{
            height: 30%;
            width: 50%;
            margin-bottom: 10px;
            padding: 10px;
        }
        .cardTop{
            height:90%;
            .icon{
                height: 100%;
                min-height: 50px;
                width: 100%;
                @include mobile{
                    height: 85%;
                }  
            }
        }
        .cardBottom{
            h2{
                padding-left: 3px;
                padding-right: 3px;
                align-self: center;
                font-size: min(1.5rem, 4vw);
            }
        }

    }
}
.aboutWrapper{
    color: #fff;
    font-size: min(2rem,4vw);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .faceContainer{
        height: auto;
        margin: 5px;
        img{
            border: 1px solid aqua;
            border-radius: 100%;
            height: min(200px, 15vw);
        }
    }

    .aboutMe{
 
        padding: 20px;
    }
}
