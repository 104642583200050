@import "../../global.scss";

.headerContainer{
    position: fixed;
    top:0;
    left:0;
    height: 50px;
    width: 100%;
    background-color: rgb(40,40,55);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    
    .resumeLink{
        background-color: rgb(40,40,55);
        position: relative;
        top: 15px;  
        justify-self: flex-end;
        padding:min(10px, 1vw);
        color: aqua;
        font-size: min(2rem, 3.5vw);
        text-decoration: none;
        border: 2px solid aqua;
        border-radius: 3%;

        &:hover{
            cursor: pointer;
    color: white;
    background-color: aqua;
    box-shadow:  0px 0px 80px #fff, 0px 0px 10px aqua, 0px 0px 21px aqua, 0px 0px 35px aqua, 0px 0px 51px aqua;
    -webkit-text-stroke: 1px rgb(40,40,55);
        }
    }
}
.wrapper{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 70%;
    @include mobile{
        justify-content: flex-start;
    }
    
    ul{ 
        padding-left: 0;
        align-self: flex-start;
        list-style: none;
        display: flex;
        width:60%;
        @include mobile{
            justify-content: flex-start;
        }

        .is-current{
            text-shadow: 0px 0px 80px #fff, 0px 0px 10px #fff, 0px 0px 21px #fff;
            a{
                text-decoration: underline aqua;
            }
          }
    }
    .navItem{
        color: #fff;
        font-size: min(2rem, 3.5vw);
        margin-left: 4vw;
        text-decoration: none;
        
        &:hover{ 
            cursor: pointer;
            text-shadow: 0px 0px 80px #fff, 0px 0px 10px #fff, 0px 0px 21px #fff;
            transition: .2s ease;

        }
        
    }
    .active{
        text-shadow: 0px 0px 80px #fff, 0px 0px 10px #fff, 0px 0px 21px #fff;
        text-decoration: underline aqua;
        transition: .2s ease;
    }

}