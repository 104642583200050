@import "../../global.scss";

.contactContainer{
    border-top: 1px solid slategrey;
    border-bottom: 2px solid slategrey;
    border-radius: 0 0 20% 20%;
    background-image: linear-gradient(rgba(10,10,20,0.8),rgba(0,0,5,.9)),url("/assets/images/swirlBG.jpg");
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color:aqua;

    h2{
        font-size: 3rem;
        color: aqua;
    }

    form{
        
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height:50%;
        
        input{
            height:7%;
            background-color: rgb(175, 200, 230);
            border: 2px solid rgb(40,40,55);
            border-radius: 3%;
            margin-bottom: 5px;
        }
        textarea{
            background-color: rgb(175, 200, 230);
            border: 2px solid rgb(40,40,55);
           
            height: 40%
        }
        button{
            padding: 5px;
            font-size: large;
            font-weight: 500;
            align-self: center;
            height: auto;
            width: 50%;
            border: 2px solid aqua;
            border-radius: 3%;
            background-color: aqua;
            margin-top: 1rem;
        }
 
    }
    .iconContainer{
        justify-self: flex-end;

        .icon{
            height:70px;
            width:70px;
            margin-left: 1rem;
            @include mobile{
                height: 40px;
                width: 40px;
            }
        }
    }
}