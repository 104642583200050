@import "../../global.scss";

.portfolio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ul {
        color: white;
        margin: 10px;
        padding: 0;
        list-style: none;
        display: flex;

        @include mobile {
            margin: 0;
            flex-wrap: wrap;
            justify-content: center;
        }
        
    }
}

.displayContainer {
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;

    @include mobile {
        width: 100%;
    }

    .displayItem{
        margin: 10px;
        width:  400px;
        height: 300px;
        border-radius: 5px;
        border: 1px solid slategray;
        background-color: rgb(20,0,60);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        position: relative;
        transition: all 0.5s ease;
        cursor: pointer;

        @include mobile {
            width: 100%;
            height: 200px;
        }

        h3{ 
            font-size: min(2rem, 5vw);
            border-radius: 15px 15px 0 0;
            display: flex;
            justify-content: center;
            height:10%;
            width: 100%;
            
            margin-top: 0;
            padding-top: 8px;
            top: 0px;
        }
        
        img {
            object-fit: fill;
            width: 100%;
            height: 60%;
            z-index: 1;
        
        }
        &:hover, &:active {
            background-color: $maincolor;
            .hoverContainer{
                visibility: visible;
                position: absolute;
                display: flex;
                z-index:1;
            }
            img {
                opacity: 0.2;
                z-index: 0;
            }
            
        }
    }

    .hoverContainer{
        font-size: smaller;
        position: absolute;
        visibility: hidden;
        display: flex;
        flex-direction: column;
        z-index: 0;
        .infoTop{
            height:auto;
            padding: 10px;}
        .techBottom{
            padding: 10px;
            justify-self: flex-end;
            span{ font-size: 1rem;
                color: aqua;
            }
        }
    }
    .bottomContainer{
        padding: 5px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height:20%;
        width: 95%;
        

        .firstLink{
            text-decoration: none;
            padding: 3px;
            border-radius: 5%;
            background-color: aqua;

            &:hover{
                box-shadow:  0px 0px 80px #fff, 0px 0px 10px aqua, 0px 0px 21px aqua, 0px 0px 35px aqua, 0px 0px 51px aqua;
    -webkit-text-stroke: 1px rgb(40,40,55);}
        }
        .secondLink{
            padding: 3px;
            color: Aqua;
            text-decoration: none;
            &:hover{              
                text-shadow: 0px 0px 80px #fff, 0px 0px 10px #fff, 0px 0px 21px #fff,0px 0px 31px #fff,0px 0px 45px #fff;
            transition: .2s ease;
            }
        }
    }
}
